@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind screens;

:root {
    font-size: 16px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue,
        Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol,
        Noto Color Emoji, sans-serif;
}

@layer base {
    *,
    input:focus,
    textarea:focus {
        outline: none !important;
        text-underline-offset: 4px;
    }
    button {
        cursor: pointer;
    }
}

@layer components {
    // 頁面
    .page {
        @apply max-w-[1440px] mx-auto relative z-[0] bg-white
    px-[30px] usm:px-[15px];
        .inner-page {
            @apply w-full max-w-[1146px] mx-auto pt-[18px];
        }
    }

    .h1 {
        @apply absolute z-[-10] opacity-0;
    }

    // cursor 鼠標
    .cp {
        @apply cursor-pointer;
    }

    // flex 置中
    .center-center {
        @apply flex justify-center items-center;
    }

    // flex between 置中
    .between-center {
        @apply flex justify-between items-center;
    }

    // 動畫 transition
    .trans {
        @apply transition-all ease-in duration-300;
    }

    // 導覽列按鈕 hover
    .filter-pale {
        @apply trans;
        &:hover {
            filter: invert(51%) sepia(99%) saturate(385%) hue-rotate(143deg) brightness(96%)
                contrast(81%);
        }
    }

    // 濾鏡
    .filter-white {
        @apply trans;
        filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg)
            brightness(103%) contrast(103%);
    }
    .filter-black {
        @apply trans;
        filter: brightness(0) saturate(100%);
    }
    .filter-grey {
        filter: brightness(0) saturate(100%) invert(71%) sepia(36%) saturate(0%) hue-rotate(276deg)
            brightness(94%) contrast(88%);
    }
    .filter-black2 {
        filter: brightness(0) saturate(100%) invert(93%) sepia(0%) saturate(692%) hue-rotate(171deg)
            brightness(91%) contrast(81%);
    }

    // 彈窗樣式
    .modal {
        @apply z-[-1] opacity-0 fixed center-center w-screen h-screen trans top-0 left-0 bg-[#1e1e1e] bg-opacity-[.7] dark:backdrop-blur-[2px] moving-element;
        // 關閉用
        .close-bg {
            @apply absolute top-0 left-0 z-0 w-full h-full moving-element;
        }
        .inner {
            @apply bg-white relative z-[12] rounded-[6px] w-[95vw] max-h-[95vh] overflow-x-hidden noScrollX dark:border-1 dark:border-opacity-[.5] dark:border-[#4B4B4B];
        }
        &.act {
            @apply z-[110] opacity-[1];
        }
    }

    // 不顯示 scroll bar
    .noScrollY {
        @apply overflow-x-hidden overflow-y-scroll;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
    }

    .noScrollX {
        @apply overflow-y-hidden overflow-x-scroll;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
    }

    // 圖片
    .img-box {
        @apply relative flex-shrink-0 overflow-hidden;
        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 0;
            @apply skelton;
        }
        img {
            @apply z-[1] absolute top-0 left-0 right-0 bottom-0 w-full h-full object-cover object-center pointer-events-none;
        }
    }

    // 置中
    .center {
        @apply absolute left-[50%] translate-x-[-50%] top-[50%] translate-y-[-50%];
    }

    // 數字等寬
    .tabular {
        @apply tabular-nums;
    }

    // 首頁 大家都在看、活動一覽
    .index-scroll-wrapper {
        @apply relative ulg:w-screen ulg:left-[50%] ulg:translate-x-[-50%];
        .bg {
            @apply z-[0] absolute top-0 right-[-61px] h-full w-[calc(100%+120px)] bg-black1;
        }
        .content {
            @apply relative z-[1] mx-auto;
            > p {
                @apply text-h5 text-black mb-[30px] text-center umd:mb-[20px];
            }
            .scroll-wrapper {
                @apply relative noScrollX;
                > div {
                    @apply flex items-center w-max pr-[30px];
                    &:last-of-type {
                        @apply usm:pr-[15px];
                    }
                }
            }
        }
    }

    // 文字 hover 底線
    .hover-text {
        @apply trans underline underline-offset-[1.5px] decoration-transparent group-hover:decoration-black dark:group-hover:decoration-[#c7c7c7];
    }
    .lighten-text {
        @apply dark:group-hover:text-[#F7F7F8];
    }

    // 圖片 hover 放大
    .hover-img {
        @apply group-hover:scale-[1.1] trans moving-element;
    }

    // 頁面大標題
    .page-title {
        @apply text-bd6b text-black pb-[20px] mb-[30px] uxs:text-bd5b border-b-1 border-[#c7c7c7] dark:border-[#4B4B4B]  border-opacity-[.5];
    }

    //  新聞標籤
    .tag {
        @apply text-pale9 text-bd3n border-1 trans border-[#00445D] border-opacity-[.4] rounded-[2px] px-[12px]
    py-[2px] hover:border-opacity-[1];
    }

    // 各等學院標題
    .academy-title {
        @apply py-[50px] px-[70px] flex relative z-[1] gap-x-[20px] ulg:px-[30px] ulg:py-[40px]
    ulg:flex-col bsm:px-[15px] bsx:px-[15px] bxs:px-[15px] ulg:gap-y-[20px];
        // 文字
        .content {
            @apply w-[550px] ulg:w-full;
            h3 {
                @apply text-h2 text-jblack;
            }
            span {
                @apply block text-bd1n text-black8 mt-[2px] mb-[26px];
            }
            h4 {
                @apply text-bd2b text-jblack my-[26px];
            }
            p {
                @apply text-bd2n text-black;
            }
        }
        // 圖片
        .image {
            @apply flex-grow relative z-[1];
            > div {
                @apply absolute top-[47%] translate-y-[-50%] right-[70px] w-full ulg:relative ulg:top-[unset] ulg:translate-y-[unset] ulg:right-[unset];
                > div {
                    @apply img-box after:content-[none] w-[810px] aspect-[810/596] ulg:w-full;
                }
            }
        }
    }

    // 文章匡
    .academy-wrapper {
        @apply relative z-[2] flex gap-x-[25px] gap-y-[20px] my-[40px] flex-wrap [&>*]:w-[calc((100%-25px)/2)] ulg:flex-col
    ulg:mt-[-100px]
    [&>*]:ulg:w-full ulg:px-[30px] ulg:gap-y-[18px] bsm:px-[15px] bsx:px-[15px] bxs:px-[15px];
    }

    // 返回列表
    .return {
        @apply text-center mx-auto w-full max-w-[400px] border-t-1 border-opacity-[.3] border-[#1e1e1e] dark:border-[#f7f7f8];
        a,
        span {
            @apply trans inline-block text-btn1 text-black px-[22px] py-[10px] text-center
      hover:bg-black hover:text-white rounded-bl-[2px] rounded-br-[2px];
        }
    }

    // 灰底線
    .border25 {
        @apply border-[#c7c7c7] dark:border-[#4B4B4B]  border-opacity-[.5];
    }

    // 登入彈窗
    .login-inner {
        > div {
            @apply text-center max-w-full px-[90px] py-[56px] usm:px-[30px] usm:py-[35px] text-white bxs:px-[20px] bxs:py-[30px];
        }
    }
    // 登入按鈕
    .login-button {
        @apply cp relative flex items-center trans border-1 text-btn2 border-[#adadad] border-opacity-[.24] rounded-[4px] w-full px-[33px] py-[15px] uxs:px-[20px];
        &:hover {
            img {
                @apply translate-x-[20px];
            }
        }
        img {
            @apply mr-[58px] uxs:mr-[20px] trans;
        }
    }

    .login-box {
        @apply [&>p]:text-center flex flex-col justify-center;
    }

    // 登入流程繼續按鈕
    .login-continue {
        @apply trans rounded-[40px] mt-[30px] w-full py-[16px] text-bd4b bg-[#141414] text-[#fff] text-opacity-[.8] hover:bg-[#2AAFCB];
    }

    .text-jblack {
        @apply dark:text-djblack;
    }
    .text-black {
        @apply dark:text-dblack;
    }
    .text-black9 {
        @apply dark:text-dblack9;
    }
    .text-black8 {
        @apply dark:text-dblack8;
    }
    .text-black7 {
        @apply dark:text-dblack7;
    }
    .text-black6 {
        @apply dark:text-dblack6;
    }
    .text-black5 {
        @apply dark:text-dblack5;
    }
    .text-black4 {
        @apply dark:text-dblack4;
    }
    .text-black3 {
        @apply dark:text-dblack3;
    }
    .text-black2 {
        @apply dark:text-dblack2;
    }
    .text-black1 {
        @apply dark:text-dblack1;
    }
    .text-white {
        @apply dark:text-dwhite;
    }

    .text-pale {
        @apply dark:text-dpale;
    }
    .text-pale9 {
        @apply dark:text-dpale9;
    }
    .text-pale8 {
        @apply dark:text-dpale8;
    }
    .text-pale7 {
        @apply dark:text-dpale7;
    }
    .text-pale6 {
        @apply dark:text-dpale6;
    }
    .text-pale5 {
        @apply dark:text-dpale5;
    }
    .text-pale4 {
        @apply dark:text-dpale4;
    }
    .text-pale3 {
        @apply dark:text-dpale3;
    }
    .text-pale2 {
        @apply dark:text-dpale2;
    }
    .text-pale1 {
        @apply dark:text-dpale1;
    }
    .text-pale0 {
        @apply dark:text-dpale0;
    }

    .border-jblack {
        @apply dark:border-djblack;
    }
    .border-black {
        @apply dark:border-dblack;
    }
    .border-black9 {
        @apply dark:border-dblack9;
    }
    .border-black8 {
        @apply dark:border-dblack8;
    }
    .border-black7 {
        @apply dark:border-dblack7;
    }
    .border-black6 {
        @apply dark:border-dblack6;
    }
    .border-black5 {
        @apply dark:border-dblack5;
    }
    .border-black4 {
        @apply dark:border-dblack4;
    }
    .border-black3 {
        @apply dark:border-dblack3;
    }
    .border-black2 {
        @apply dark:border-dblack2;
    }
    .border-black1 {
        @apply dark:border-dblack1;
    }
    .border-white {
        @apply dark:border-dwhite;
    }

    .border-pale {
        @apply dark:border-dpale;
    }
    .border-pale9 {
        @apply dark:border-dpale9;
    }
    .border-pale8 {
        @apply dark:border-dpale8;
    }
    .border-pale7 {
        @apply dark:border-dpale7;
    }
    .border-pale6 {
        @apply dark:border-dpale6;
    }
    .border-pale5 {
        @apply dark:border-dpale5;
    }
    .border-pale4 {
        @apply dark:border-dpale4;
    }
    .border-pale3 {
        @apply dark:border-dpale3;
    }
    .border-pale2 {
        @apply dark:border-dpale2;
    }
    .border-pale1 {
        @apply dark:border-dpale1;
    }
    .border-pale0 {
        @apply dark:border-dpale0;
    }

    .bg-jblack {
        @apply dark:bg-djblack;
    }
    .bg-black {
        @apply dark:bg-dblack;
    }
    .bg-black9 {
        @apply dark:bg-dblack9;
    }
    .bg-black8 {
        @apply dark:bg-dblack8;
    }
    .bg-black7 {
        @apply dark:bg-dblack7;
    }
    .bg-black6 {
        @apply dark:bg-dblack6;
    }
    .bg-black5 {
        @apply dark:bg-dblack5;
    }
    .bg-black4 {
        @apply dark:bg-dblack4;
    }
    .bg-black3 {
        @apply dark:bg-dblack3;
    }
    .bg-black2 {
        @apply dark:bg-dblack2;
    }
    .bg-black1 {
        @apply dark:bg-dblack1;
    }
    .bg-white {
        @apply dark:bg-dwhite;
    }

    .bg-pale {
        @apply dark:bg-dpale;
    }
    .bg-pale9 {
        @apply dark:bg-dpale9;
    }
    .bg-pale8 {
        @apply dark:bg-dpale8;
    }
    .bg-pale7 {
        @apply dark:bg-dpale7;
    }
    .bg-pale6 {
        @apply dark:bg-dpale6;
    }
    .bg-pale5 {
        @apply dark:bg-dpale5;
    }
    .bg-pale4 {
        @apply dark:bg-dpale4;
    }
    .bg-pale3 {
        @apply dark:bg-dpale3;
    }
    .bg-pale2 {
        @apply dark:bg-dpale2;
    }
    .bg-pale1 {
        @apply dark:bg-dpale1;
    }
    .bg-pale0 {
        @apply dark:bg-dpale0;
    }

    .moving-element {
        @apply will-change-transform;
        transform: translateZ(0);
    }
}

@keyframes slideIn {
    0% {
        opacity: 0;
        transform: translateY(-40px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideTop {
    0% {
        transform: translateY(40px);
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes slideDown {
    0% {
        transform: translateY(-40px);
    }
    100% {
        transform: translateY(0);
    }
}

.slideTop {
    animation: slideDown 0.3s ease-in;

    @media screen and (max-width: 1023px) {
        animation: slideTop 0.3s ease-in !important;
    }
}

.slideIn {
    animation: slideIn 0.3s ease-in;
}

// 取消選取
.no-select {
    -webkit-user-select: none; /*Chrome, Opera (older versions), IOS Safari*/
    -webkit-touch-callout: none; /*Safari*/
    -moz-user-select: none; /*Mozilla*/
    -ms-user-select: none;
    user-select: none;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.fade-in {
    animation-delay: 0.2s;
    animation: fadeIn 0.25s ease-in;
}

@keyframes skelton {
    to {
        background-position-x: -200%;
    }
}

// loading skelton
.skelton-all {
    > * {
        opacity: 0.5;
        background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
        border-radius: 4px;
        background-size: 200% 100%;
        animation: 1s skelton linear infinite;
    }
}

.skelton {
    opacity: 0.5;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 4px;
    background-size: 200% 100%;
    animation: 1s skelton linear infinite;
}

:is(.dark .skelton) {
    opacity: 0.4;
}

:is(.dark .skelton-all) {
    > * {
        opacity: 0.4;
    }
}

// 登入漸層匡
.login-gradient {
    border-style: solid;
    border-width: 1px;
    border-image: linear-gradient(135deg, rgb(81, 81, 81), rgb(0, 0, 0, 0)) 1;
}

#image-crop-modal {
    display: block;
    position: fixed;
    width: auto;
    max-width: 100vw;
    height: auto;
    max-height: 100vh;
    z-index: 2000;
    left: 50%;
    top: 50%;
    /* Use this for centering if unknown width/height */
    transform: translate(-50%, -50%);
    background: white;
    box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    padding: unset !important;
    .image-crop-modal-inner {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 1rem 1rem 0 1rem;
    }

    canvas {
        max-width: 100%;
        max-height: 100%;
    }

    .slider {
        display: flex;
        justify-content: center;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .slider input {
        width: 50%;
    }
    img {
        max-width: unset !important;
    }
}

// 全站反白
::selection {
    background-color: #29afcb;
    color: #fff !important;
}

.user-drag {
    -webkit-user-drag: none;
}

.selected-font {
    font-family: 'Noto Sans TC', sans-serif, 'Roboto Slab' !important;
}

@keyframes taskNotice {
    0% {
        background: #00445d;
    }
    100% {
        background: #0083a0;
    }
}

.task-notice {
    animation: taskNotice 1s steps(2) infinite;
}

:is(.dark .filter-black8) {
    filter: brightness(0) saturate(100%) invert(78%) sepia(0%) saturate(0%) hue-rotate(352deg)
        brightness(91%) contrast(93%);
}
